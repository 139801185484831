import React from "react";

import classNames from "classnames";

import "./Input.scss";

export interface IInput {
  type: "text" | "password";
  name?: string;
  id?: string;
  disabled?: boolean;
  value?: string;
}

const Input: React.FC<IInput> = ({
  type = "text",
  name,
  id,
  disabled,
  value,
}) => {
  const inputClass = classNames({
    Input: true,
  });

  return (
    <input
      className={inputClass}
      type={type}
      name={name}
      id={id}
      disabled={disabled}
      value={value}
      autoComplete="off"
    />
  );
};

export default Input;
