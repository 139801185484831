import React from "react";

import Logo from "../Logo/Logo";
import "./Header.scss";

function Header() {
  return (
    <header className="Header">
      <div className="Header__logo">
        <Logo />
      </div>
    </header>
  );
}

export default Header;
