import React from "react";

import classNames from "classnames";

import "./Button.scss";

export interface IButton {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  name?: string;
  type?: "submit" | "reset" | "button";
  color?: "primary" | "secondary" | "tertiary";
}

const Button: React.FC<IButton> = ({
  children,
  onClick,
  disabled = false,
  name,
  type,
  color = "primary",
}) => {
  const ButtonClass = classNames({
    Button: true,
    isDisabled: disabled,
    _primary: color === "primary",
    _secondary: color === "secondary",
    _tertiary: color === "tertiary",
  });

  return (
    <button
      className={ButtonClass}
      onClick={onClick}
      name={name}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
