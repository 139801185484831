import React from "react";

import HowItWorks from "../HowItWorks/HowItWorks";
import KitchenSink from "../KitchenSink/KitchenSink";
import "./Page.scss";

function Page() {
  return (
    <div className="Page">
      <KitchenSink />
      <HowItWorks />
    </div>
  );
}

export default Page;
