import React from "react";

import classNames from "classnames";

import "./Gift.scss";

export interface IGift {
  title: string;
  subtitle?: string;
  imgSrc?: string;
  imgAlt?: string;
  inverse?: boolean;
}

const Gift: React.FC<IGift> = ({
  title,
  subtitle,
  imgSrc,
  imgAlt,
  inverse = false,
}) => {
  const giftClass = classNames({
    Gift: true,
    _inverse: inverse,
  });

  return (
    <div className={giftClass}>
      {imgSrc && (
        <div className="Gift__image">
          <img src={imgSrc} alt={imgAlt} />
        </div>
      )}
      {title && <h3 className="Gift__title">{title}</h3>}
      {subtitle && <h3 className="Gift__subtitle">{subtitle}</h3>}
    </div>
  );
};

export default Gift;
