import React from "react";

import classNames from "classnames";

import "./ChosenGift.scss";

export interface IChosenGift {
  business: string;
  location: string;
  giftAmount: number;
  backgroundImgSrc?: string;
  giftImgSrc?: string;
  giftImgAlt?: string;
  giftType?: "digital gift card" | "physical gift card";
}

const ChosenGift: React.FC<IChosenGift> = ({
  business,
  location,
  giftAmount,
  backgroundImgSrc,
  giftImgSrc,
  giftImgAlt,
  giftType = "digital gift card",
}) => {
  const chosenGiftClass = classNames({
    ChosenGift: true,
    _noGiftImg: giftImgSrc === undefined,
    _noBackground: backgroundImgSrc === undefined,
  });

  return (
    <div
      className={chosenGiftClass}
      style={{ backgroundImage: `url(${backgroundImgSrc})` }}
    >
      <div className="ChosenGift__wrapper">
        <h3 className="ChosenGift__business">{business}</h3>
        <p className="ChosenGift__location">{location}</p>
        {giftImgSrc && (
          <div className="ChosenGift__image">
            <img src={giftImgSrc} alt={giftImgAlt} />
          </div>
        )}
        {giftAmount && (
          <div className="ChosenGift__amount">
            <span className="ChosenGift__amount_value">
              <span className="ChosenGift__amount_value_currency">$</span>
              {giftAmount}&nbsp;
            </span>
            <span className="ChosenGift__amount_type">{giftType}</span>{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChosenGift;
