import React from "react";

import Button from "../Button/Button";
import ChosenGift from "../ChosenGift/ChosenGift";
import { IGift } from "../Gift/Gift";
import GiftCard from "../GiftCard/GiftCard";
import Gifts from "../Gifts/Gifts";
import Input from "../Input/Input";
import Label from "../Label/Label";
import PreSelectedGift from "../PreSelectedGift/PreSelectedGift";
import "./KitchenSink.scss";

const mockGifts: IGift[] = [
  {
    title: "Garzia Spa",
    subtitle: "$100 Digital Gift Card",
    imgSrc: "http://placekitten.com/640/400",
    imgAlt: "$100 Digital Gift Card at Garzia Spa",
  },
  {
    title: "Beekeeper's Apothecary Special Sauce 2024",
    subtitle: "$100 Digital Gift Card",
    imgSrc: "http://placekitten.com/320/200",
    imgAlt:
      "$100 Digital Gift Car at Beekeeper's Apothecary Special Sauce 2024",
  },
];

const mockGiftsInversed: IGift[] = [
  {
    title: "Garzia Spa",
    subtitle: "$100 Digital Gift Card",
    imgSrc: "http://placekitten.com/640/400",
    imgAlt: "$100 Digital Gift Card at Garzia Spa",
    inverse: true,
  },
  {
    title: "Beekeeper's Apothecary Special Sauce 2024",
    subtitle: "$100 Digital Gift Card",
    imgSrc: "http://placekitten.com/320/200",
    imgAlt:
      "$100 Digital Gift Car at Beekeeper's Apothecary Special Sauce 2024",
    inverse: true,
  },
];

function KitchenSink() {
  return (
    <div className="KitchenSink">
      <div className="Boundary">
        <h1>Header 1</h1>
        <h2>Header 2</h2>
        <h3>Header 3</h3>
        <h4>Header 4</h4>
        <h5>Header 5</h5>
        <h6>Header 6 (SMALL CAPS)</h6>

        <p>
          <strong>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            dapibus ante in nulla sodales lobortis.
          </strong>{" "}
          In eu est et quam egestas placerat non ac magna. Donec purus dolor,
          venenatis vitae imperdiet ac, feugiat vel ante. Pellentesque vehicula
          urna ac nisi molestie pharetra.{" "}
          <em>
            Class aptent taciti sociosqu ad litora torquent per conubia nostra,
            per inceptos himenaeos. Ut pretium magna eget malesuada feugiat.
            Vivamus tristique luctus dictum
          </em>
          .
        </p>

        <p>
          <b>
            Donec vel diam neque. Ut tempus at mi id tincidunt. Donec elementum
            orci eu diam sollicitudin, eu condimentum lacus elementum. Sed neque
            lectus, rhoncus in eros ultricies, imperdiet finibus massa.
            Pellentesque ut egestas velit. Nunc interdum ullamcorper diam.
            Aliquam luctus, neque at elementum suscipit, mi augue bibendum
            sapien, quis cursus nisi risus in ipsum.
          </b>{" "}
          Pellentesque finibus vehicula erat, vitae viverra orci. Sed rutrum
          arcu vitae mauris hendrerit ultricies. In iaculis lacus molestie enim
          porta eleifend. Nulla bibendum orci ac orci maximus ornare.
        </p>
        <ul>
          <li>List Item</li>
          <li>List Item</li>
          <li>List Item</li>
          <li>List Item</li>
        </ul>
        <ol>
          <li>List Item</li>
          <li>List Item</li>
          <li>List Item</li>
          <li>List Item</li>
        </ol>

        <div>
          <Button type="button" onClick={() => {}}>
            Select this gift
          </Button>
          <Button type="button" onClick={() => {}} disabled>
            Select this gift
          </Button>
          <Button type="button" color="primary" onClick={() => {}}>
            Select this gift
          </Button>
          <Button type="button" color="primary" onClick={() => {}} disabled>
            Select this gift
          </Button>
          <Button type="button" color="secondary" onClick={() => {}}>
            Send your own gift
          </Button>
          <Button type="button" color="secondary" onClick={() => {}} disabled>
            Send your own gift
          </Button>
          <Button type="button" color="tertiary" onClick={() => {}}>
            How it works
          </Button>
          <Button type="button" color="tertiary" onClick={() => {}} disabled>
            How it works
          </Button>
        </div>

        <Gifts title="Chicago, IL" gifts={mockGifts} />
        <Gifts
          title="Grand Rapids, MI"
          gifts={mockGiftsInversed}
          inverse={true}
        />

        <GiftCard
          giverName="Eric"
          receiverName="Miped"
          giftAmount={100}
          giftNote="Thanks for the soups! Get yourself some Christmas noodles. Merry Christmas!"
          cardImgSrc="https://localgift-emails.madcity.gg/images/cards/HOLIDAYS_CHRISTMAS_ALT@2x.png"
          cardImgAlt="Merry Christmas!"
        />

        <GiftCard
          giverName="Eric"
          receiverName="Miped"
          giftAmount={100}
          giftNote="Thanks for the soups! Get yourself some Christmas noodles. Merry Christmas!"
          cardImgSrc="https://localgift-emails.madcity.gg/images/cards/FOR_FOODIES_ALT@2x.png"
          cardImgAlt="Merry Christmas!"
        />

        <GiftCard
          giverName="Eric"
          receiverName="Miped"
          giftAmount={100}
          giftNote="Thanks for the soups! Get yourself some Christmas noodles. Merry Christmas! Thanks for the soups! Get yourself some Christmas noodles. Merry Christmas! Thanks for the soups! Get yourself some Christmas noodles. Merry Christmas!"
          cardImgSrc="https://localgift-emails.madcity.gg/images/cards/FOR_FOODIES_ALT@2x.png"
          cardImgAlt="Merry Christmas!"
        />

        <GiftCard
          giverName="Eric"
          receiverName="Miped"
          giftAmount={100}
          cardImgSrc="https://localgift-emails.madcity.gg/images/cards/HOLIDAYS_CHRISTMAS_ALT@2x.png"
          cardImgAlt="Merry Christmas!"
        />

        <GiftCard
          giverName="Eric"
          receiverName="Miped"
          giftAmount={100}
          giftNote="Thanks for the soups! Get yourself some Christmas noodles. Merry Christmas!"
        />

        <GiftCard giverName="Eric" receiverName="Miped" giftAmount={100} />

        <ChosenGift
          business="Mr. Bun Chocolates"
          location="Santa Barbara, CA"
          giftAmount={100}
          giftType="digital gift card"
          backgroundImgSrc="https://localgift-emails.madcity.gg/images/cards/HOLIDAYS_CHRISTMAS_ALT@2x.png"
          giftImgSrc="http://placekitten.com/640/400"
          giftImgAlt="Chocolate Cats"
        />

        <ChosenGift
          business="Mad City, LLC"
          location="Chicago, IL"
          giftAmount={250}
          giftType="digital gift card"
          backgroundImgSrc="https://localgift-emails.madcity.gg/images/cards/FOR_FOODIES_ALT@2x.png"
          giftImgSrc="http://placekitten.com/g/640/400"
          giftImgAlt="Goods n' Services"
        />

        <ChosenGift
          business="Mad City, LLC"
          location="Chicago, IL"
          giftAmount={250}
          giftType="digital gift card"
          giftImgSrc="http://placekitten.com/g/640/400"
          giftImgAlt="Goods n' Services"
        />

        <ChosenGift
          business="Mad City, LLC"
          location="Chicago, IL"
          giftAmount={250}
          giftType="digital gift card"
          backgroundImgSrc="https://localgift-emails.madcity.gg/images/cards/FOR_FOODIES_ALT@2x.png"
        />

        <ChosenGift
          business="Mad City, LLC"
          location="Chicago, IL"
          giftAmount={250}
          giftType="digital gift card"
        />

        <div className="Form">
          <div className="Form__block">
            <Label htmlFor="giftValue">Gift Value</Label>
            <Input
              type="text"
              name="giftValue"
              id="giftValue"
              value="$250"
              disabled
            />
          </div>
          <div className="Form__block">
            <Label htmlFor="firstName">Your first name</Label>
            <Input type="text" name="firstName" id="firstName" />
          </div>
          <div className="Form__block">
            <Label htmlFor="lastName">Your last name</Label>
            <Input type="text" name="lastName" id="lastName" />
          </div>
          <div className="Form__block">
            <Label htmlFor="email">
              Your email address (for digital delivery)
            </Label>
            <Input type="text" name="email" id="email" />
          </div>
        </div>

        <PreSelectedGift
          gift={mockGifts[0]}
          businessAddress="730 Stoneside Ave. #105 Santa Barbara, CA"
          businessDescription="A Santa Barbara institution for over 70 years, featuring a huge variety of handmade chocolates and confectionaries."
          selectButtonOnClick={() => {}}
        />
      </div>
    </div>
  );
}

export default KitchenSink;
