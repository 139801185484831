import React from "react";

import classNames from "classnames";

import Gift, { IGift } from "../Gift/Gift";
import "./Gifts.scss";

interface IGifts {
  title: string;
  gifts: IGift[];
  inverse?: boolean;
}

const Gifts: React.FC<IGifts> = ({ title, gifts, inverse = false }) => {
  const giftsClass = classNames({
    Gifts: true,
    _inverse: inverse,
  });

  return (
    <div className={giftsClass}>
      {title && <h1 className="Gifts__title">{title}</h1>}
      {gifts.map((gift) => (
        <div className="Gifts__gift">
          <Gift
            title={gift.title}
            subtitle={gift.subtitle}
            imgSrc={gift.imgSrc}
            imgAlt={gift.imgAlt}
            inverse={gift.inverse}
          />
        </div>
      ))}
    </div>
  );
};

export default Gifts;
