import React from "react";

import classNames from "classnames";

import "./GiftCard.scss";

export interface IGiftCard {
  giftAmount: number;
  giftNote?: string;
  cardImgSrc?: string;
  cardImgAlt?: string;
  giverName: string;
  receiverName: string;
}

const GiftCard: React.FC<IGiftCard> = ({
  giftAmount,
  giftNote,
  cardImgSrc,
  cardImgAlt,
  giverName,
  receiverName,
}) => {
  const giftCardClass = classNames({
    GiftCard: true,
    _noNote: giftNote === undefined,
    _noImg: cardImgSrc === undefined,
  });

  return (
    <div className={giftCardClass}>
      <p className="GiftCard__intro">
        <span className="GiftCard__intro_name">{giverName}</span> sent you a
        Local Gift!
      </p>
      <div className="GiftCard__wrapper">
        {cardImgSrc && (
          <div className="GiftCard__image">
            <img src={cardImgSrc} alt={cardImgAlt} />
          </div>
        )}
        {giftAmount && (
          <div className="GiftCard__amount">
            <span className="GiftCard__amount_title">Gift</span>{" "}
            <span className="GiftCard__amount_value">
              <span className="GiftCard__amount_value_currency">$</span>
              {giftAmount}&nbsp;
            </span>
            <span className="GiftCard__amount_title">Card</span>
          </div>
        )}
        {giftNote && (
          <div className="GiftCard__note">
            <p className="GiftCard__note_receiver">{receiverName},</p>
            <p className="GiftCard__note_copy">{giftNote}</p>
            <p className="GiftCard__note_giver">-{giverName}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default GiftCard;
