import React from "react";

import Logo from "../Logo/Logo";
import "./Footer.scss";

function Footer() {
  return (
    <footer className="Footer">
      <div className="Footer__logo">
        <Logo />
      </div>
    </footer>
  );
}

export default Footer;
