import React from "react";

import classNames from "classnames";

import Button from "../Button/Button";
import Gift, { IGift } from "../Gift/Gift";
import "./PreSelectedGift.scss";

export interface IPreSelectedGift {
  gift: IGift;
  businessAddress?: string;
  businessDescription?: string;
  selectButtonOnClick: () => void;
}

const PreSelectedGift: React.FC<IPreSelectedGift> = ({
  gift,
  businessAddress,
  businessDescription,
  selectButtonOnClick,
}) => {
  const preSelectedGiftClass = classNames({
    PreSelectedGift: true,
  });

  return (
    <div className={preSelectedGiftClass}>
      <div className="PreSelectedGift__gift">
        <Gift
          title={gift.title}
          subtitle={gift.subtitle}
          imgSrc={gift.imgSrc}
          imgAlt={gift.imgAlt}
          inverse={true}
        />
      </div>
      {businessAddress && (
        <div className="PreSelectedGift__address">{businessAddress}</div>
      )}
      {businessDescription && (
        <div className="PreSelectedGift__description">
          {businessDescription}
        </div>
      )}
      <div className="PreSelectedGift__actions">
        <Button onClick={selectButtonOnClick}>Select this gift</Button>
      </div>
    </div>
  );
};

export default PreSelectedGift;
