import React from "react";

import HowToImage1 from "../../images/how-to-1@2x.png";
import HowToImage2 from "../../images/how-to-2@2x.png";
import HowToImage3 from "../../images/how-to-3@2x.png";
import "./HowItWorks.scss";

function HowItWorks() {
  return (
    <div className="HowItWorks">
      <div className="Boundary">
        <h1 className="HowItWorks__title">How It Works</h1>
        <p className="HowItWorks__intro">
          Welcome to Local Gift. Someone has curated a custom gift page
          featuring several local businesses they think you'd enjoy.
        </p>
        <div className="HowItWorks__blocks">
          <div className="HowItWorks__block">
            <div className="HowItWorks__block_illustration">
              <img
                src={HowToImage1}
                alt="Browse & Pick Your Gift"
                width={86}
                height={68}
              />
            </div>
            <h2>1. Browse & Pick Your Gift</h2>
            <p>
              Take a look at your custom gift page and choose what you'd like
              best.
            </p>
          </div>
          <div className="HowItWorks__block">
            <div className="HowItWorks__block_illustration">
              <img
                src={HowToImage2}
                alt="Enter Your Delivery Info"
                width={72}
                height={78}
              />
            </div>
            <h2>2. Enter Your Delivery Info</h2>
            <p>
              Let us know where you want to receive your gift, and we'll take
              care of the rest.
            </p>
          </div>
          <div className="HowItWorks__block">
            <div className="HowItWorks__block_illustration">
              <img src={HowToImage3} alt="That's It!" width={109} height={59} />
            </div>
            <h2>3. That's It!</h2>
            <p>
              Enjoy your gift. Get to know a new local business. And know that
              you are appreciated!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
