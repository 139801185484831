import React from "react";

import "./App.scss";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Page from "./components/Page/Page";

function App() {
  return (
    <div className="App">
      <div className="North">
        <div className="Boundary">
          <Header />
        </div>
      </div>
      <main className="Main">
        <Page />
      </main>
      <footer className="South">
        <div className="Boundary">
          <Footer />
        </div>
      </footer>
    </div>
  );
}

export default App;
