import React from "react";

import classNames from "classnames";

import "./Label.scss";

export interface ILabel {
  children: React.ReactNode;
  htmlFor?: string;
}

const Label: React.FC<ILabel> = ({ children, htmlFor }) => {
  const labelClass = classNames({
    Label: true,
    _hasHtmlFor: htmlFor !== undefined,
  });

  return (
    <label className={labelClass} htmlFor={htmlFor}>
      {children}
    </label>
  );
};

export default Label;
